<template>
	<div class="page">
		<div class="xc_top_box">
			<div class="w1240 top_con">
				<div class="logobox">
					<img class="img" src="../assets/static/logoimg.png"/>
				</div>
				<div class="step_box">
					<div class="stepitem relative">
						<div class="text1_hover">1</div>
						<div class="text2_hover">我的采购车</div>
						<img class="img" src="../assets/static/Rectangle317.png" />
					</div>
					<div class="stepitem relative">
						<div class="text1_hover">2</div>
						<div class="text2_hover">确认订单信息</div>
						<img class="img" src="../assets/static/Rectangle319.png" />
					</div>
					<div class="stepitem relative">
						<div class="text1">3</div>
						<div class="text2">提交订单成功</div>
						<img class="img" src="../assets/static/Rectangle318.png" />
					</div>
				</div>
			</div>
		</div>
		<div class="block20"></div>
		<!-- 地址和发票 -->
		<div class="address_invoice_box w1240">
			<div class="address_box">
				<div class="address_invoice_type">
					<div class="line"></div>
					<div class="text">收货信息</div>
					<div class="morediv" @click="showTost('供应商已锁定地址信息，请到个人信息或者向管理员申请修改！')">
						<div class="text1">修改地址</div>
						<div class="text2">+添加地址</div>
					</div>
				</div>
				<div class="addressobj" v-if="addressobj">
					<div class="itemobj">
						<div class="lable1">收货单位：</div>
						<div class="value1">{{addressobj.company}}</div>
					</div>
					<div class="itemobj">
						<div class="lable1">联系人：</div>
						<div class="value1">{{addressobj.name}}</div>
					</div>
					<div class="itemobj">
						<div class="lable1">联系方式：</div>
						<div class="value1">{{addressobj.phone}}</div>
					</div>
					<div class="itemobj">
						<div class="lable1">收货地址：</div>
						<div class="value1">{{ addressobj.province }}{{ addressobj.city }}{{ addressobj.area }}{{ addressobj.address }}</div>
					</div>
				</div>
			</div>
			<div class="invoice_box">
				<div class="address_invoice_type">
					<div class="line"></div>
					<div class="text">发票信息</div>
				</div>
				<div class="invoiceobj">
					<div class="itemobj">
						<div class="lable1">发票类型：</div>
						<div class="value1">
							<span v-if="invoiceDetail.invoiceType==0">普通发票</span>
							<span v-if="invoiceDetail.invoiceType==1">专用发票</span>
							<span v-if="invoiceDetail.invoiceType==2">线下沟通</span>
							<el-dropdown trigger="click" @command="handcommand1">
							   <span class="el-dropdown-link">
							     修改<i class="el-icon-arrow-down el-icon--right"></i>
							   </span>
							   <el-dropdown-menu slot="dropdown">
							     <el-dropdown-item :command="0">普通发票</el-dropdown-item>
							     <el-dropdown-item :command="1">专用发票</el-dropdown-item>
								 <el-dropdown-item :command="2">线下沟通</el-dropdown-item>
							   </el-dropdown-menu>
							</el-dropdown>
						</div>
					</div>
					<div class="itemobj" v-if="invoiceDetail.invoiceType!=2">
						<div class="lable1">企业名称：</div>
						<div class="value1">
							<input placeholder="请输入内容" v-model="invoiceDetail.comName"/>
						</div>
					</div>
					<div class="itemobj" v-if="invoiceDetail.invoiceType!=2">
						<div class="lable1">企业税号：</div>
						<div class="value1">
							<input placeholder="请输入内容" v-model="invoiceDetail.dutyNo"/>
						</div>
					</div>
					<div class="itemobj" v-if="invoiceDetail.invoiceType!=2">
						<div class="lable1">企业开户行信息：</div>
						<div class="value1">
							<input placeholder="请输入内容" v-model="invoiceDetail.openBank"/>
						</div>
					</div>
					<div class="itemobj" v-if="invoiceDetail.invoiceType!=2">
						<div class="lable1">银行账号：</div>
						<div class="value1">
							<input placeholder="请输入内容" v-model="invoiceDetail.accountNo"/>
						</div>
					</div>
					<div class="itemobj" v-if="invoiceDetail.invoiceType!=2">
						<div class="lable1">联系电话：</div>
						<div class="value1">
							<input placeholder="请输入内容" type="number" v-model="invoiceDetail.phone"/>
						</div>
					</div>
					<div class="itemobj" v-if="invoiceDetail.invoiceType!=2">
						<div class="lable1">联系邮箱：</div>
						<div class="value1">
							<input placeholder="请输入内容" v-model="invoiceDetail.email"/>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="block20"></div>
		<!-- 订单商品列表 -->
		<div class="pay_order_goods_box w1240" v-if="orderList&&orderList.length>0">
			<div class="pay_top_box">
				<div class="line"></div>
				<div class="text">{{bsnName}}</div>
				<div class="morediv">
					<span class="mixicon iconfont icon-peisongzhong"></span>
					<span class="text1">配送说明</span>
					<span class="text2">满0元包邮</span>
				</div>
			</div>
			<div class="pay_goods_box">
				<div class="type_box">
					<div class="text1">商品信息</div>
					<div class="text2">供应价</div>
					<div class="text3">数量</div>
					<div class="text4">实付价</div>
				</div>
				<div class="goods_box">
					<div class="itemobj" v-for="(item,index) in orderList[0].goodsList" :key="index">
						<div class="con_box">
							<div class="img"><img :src="item.skuImg" /></div>
							<div class="name_box">
								<div class="text1">{{item.goodsobj.goodsName}}</div>
								<div class="text2">生产厂家：{{item.goodsobj.manufacturerName}}</div>
								<div class="text3">生产日期： {{item.goodsobj.medicineDrugs.productionDate}}</div>
								<div class="text3">有效期： {{item.goodsobj.medicineDrugs.validityDate}}</div>
							</div>
							<div class="sku_box">
								<div class="text1">规格：{{item.goodsobj.medicineDrugs.baseSku}}</div>
								<div class="text2">单位：{{item.skuName || ""}}</div>
								<div class="text3">批号/批次：{{item.batchid || item.goodsobj.medicineDrugs.batchNum}}</div>
							</div>
						</div>
						<div class="con_box1">
							<div class="text1" v-if="payori=='exchange'">{{item.goodsPirce}}积分</div>
							<div class="text1" v-else>￥{{item.goodsPirce}}</div>
						</div>
						<div class="con_box2">
							<div class="text1">{{item.goodsNum}}</div>
						</div>
						<div class="con_box3">
							<div class="text1" v-if="payori=='exchange'">{{item.danprice}}积分</div>
							<div class="text1" v-else>￥{{item.danprice}}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="block20" v-if="payori=='goods'"></div>
		<!-- 可用的优惠券 -->
		<div class="pay_canuse_coupon_box w1240" v-if="payori=='goods'">
			<div class="pay_top_box">
				<div class="line"></div>
				<div class="text">可用优惠券</div>
			</div>
			<div class="coupon_data_box">
				<div class="cpdatalist">
					<div class="cpitem" :class="{'active':index==selectcpindex}" v-for="(item,index) in couponlist" :key="index" @click="selectCoupon(index)">
						<div class="topbox">
							<div class="amout">￥{{ item.amount }}<span>满{{ item.minAmount }}元</span></div>
							<div class="timer">{{item.startTime}}至{{item.endTime}}</div>
						</div>
						<div class="bottombox">[
							<span v-if="item.couponType==0">全品通用券</span>
							<span v-if="item.couponType==1">限定商品券</span>
						]</div>
					</div>
				</div>
				<div class="amout_text">
					<div class="text1">金额抵用</div>
					<div class="text2">￥{{selectcp?selectcp.amount:"0.00"}}</div>
					<div class="text3">使用优惠券{{selectcp?1:0}}张，优惠{{selectcp?selectcp.amount:"0.00"}}元</div>
				</div>
			</div>
		</div>
		<div class="block20"></div>
		<!-- 可用的优惠券 -->
		<div class="pay_canuse_coupon_box w1240" v-if="payType=='CORPORATE'">
			<div class="pay_top_box">
				<div class="line"></div>
				<div class="text">上传对公凭证</div>
			</div>
			<div class="corporate_data_box">
				<imageSetCom :list="corporateList" @removeimglist="removeimglist" @addimglist="addimglist" tips="最多上传2张，只能上传jpg/png文件，且不超过2m"></imageSetCom>
			</div>
		</div>
		<div class="block20"></div>
		<!-- 订单商品列表 -->
		<div class="pay_analysis_box w1240">
			<div class="select_box" v-if="payType=='ALIPAY_NATIVE' || payType=='WEIXIN_NATIVE' || payType=='UNIONPAY_NATIVE' || payType=='CASH' || payType=='CORPORATE'">
				<el-radio v-model="payType" label="WEIXIN_NATIVE">微信支付</el-radio>
				<el-radio v-model="payType" label="ALIPAY_NATIVE">支付宝支付</el-radio>
				<!-- <el-radio v-model="payType" label="UNIONPAY_NATIVE">银联支付</el-radio> -->
				<!-- <el-radio v-model="payType" label="CASH">货到付款</el-radio> -->
				<el-radio v-model="payType" label="CORPORATE">对公支付</el-radio>
			</div>
			<div class="select_box" v-else>
				<el-radio v-model="payType" label="POINT">积分换购</el-radio>
			</div>
			<div class="right_box">
				<div class="text_box">
					<div class="text1">商品数量</div>
					<div class="text2">{{goodLength}}件</div>
				</div>
				<div class="text_box">
					<div class="text1">商品合计</div>
					<div class="text3" v-if="payori=='exchange'">{{oriAllmoney}}积分</div>
					<div class="text3" v-else>¥{{oriAllmoney}}</div>
				</div>
				<div class="text_box">
					<div class="text1">运费</div>
					<div class="text2">¥0.00</div>
				</div>
				<div class="text_box">
					<div class="text1">应付总额</div>
					<div class="text3" v-if="payori=='exchange'">{{Allmoney}}积分</div>
					<div class="text3" v-else>¥{{Allmoney}}</div>
				</div>
				<div class="orderbtn" v-if="payori=='team'&&groupType==0" @click="submitOrder">创建拼团</div>
				<div class="orderbtn" v-else-if="payori=='team'&&groupType==1" @click="submitOrder">参加拼团</div>
				<div class="orderbtn" v-else @click="submitOrder">提交订单</div>
			</div>
		</div>
		
		<div class="block40"></div>
		<!-- 支付页面 -->
		<doPay ref="refdoPay" :is-show="isShowPay" :payType="payType" :min-price="Allmoney" @clickpayclose="clickpayclose" />
	</div>
</template>

<script>
import {mapState} from 'vuex'
import { showLoading, hideLoading } from '@/common/loading.js';
import doPay from '@/components/doPay/doPay.vue';
import imageSetCom from "../components/imagesSetting.vue";
export default {
	components: {
	  doPay,
	  imageSetCom
	},
	data() {
		return {
			uid:"",
			number:1,
			orderList:[],
			physical:1,//虚实物 0虚物 1实物
			points:0,//消耗积分
			addressobj: null, //默认地址对象
			goodLength: 0, // 总共多少件商品
			Allmoney: null, //合计总金额
			oriAllmoney: 0, //原来总额
			selectcp: null, //选择的优惠券
			selectcpindex:-1, //当前优惠券选择的下表，-1代表不使用
			couponlist: [], //可选优惠集合
			opencoupon: false, //打开选择优惠券
			payori: '', //goods普通商品 tejia exchange team
			payType:"",//支付类型 MA小程序 WEB网页（失效） CASH货到付款 POINT积分 ALIPAY_NATIVE支付宝扫码 WEIXIN_NATIVE微信扫码 UNIONPAY_NATIVE银联扫码
			groupType:0,//团类型 0创建 1加入
			groupNo:"",//拼团号
			invoiceDetail:{
				accountNo:'',//账号
				comName:'',//公司名称	
				dutyNo:'',//	企业税号
				email:'',//邮箱
				invoiceType:0,//发票类型 0普通 1专票		false
				openBank:'',//企业开户行
				phone:'',//电话phone
			},
			isShowPay: false, // 支付弹窗
			corporateList:[],//对公证明
		};
	},
	mounted: function() {
		var _this = this
		if(!this.isLogin){
			this.$alert('登录时限失效，请重新登录~', '温馨提示', {
			    confirmButtonText: '确定',
			    callback: action => {
			        _this.$router.push({
			        	name: "home"
			        });
			    }
			});
			return;
		}
		if(this.$route.query&&this.$route.query.ori){
			this.payori = this.$route.query.ori
			if(this.payori=="goods" || this.payori=="tejia" || this.payori=="team"){
				this.payType = "WEIXIN_NATIVE"
			}
			if(this.payori=="exchange"){
				this.payType = "POINT"
			}
			if(this.$route.query.groupType){
				//团类型 0创建 1加入
				this.groupType = Number(this.$route.query.groupType)
			}
			if(this.$route.query.groupNo){
				//团号
				this.groupNo = this.$route.query.groupNo
			}
			if(localStorage.getItem("orderList")){
				var orderList = JSON.parse(localStorage.getItem("orderList"))
				this.orderList = orderList
				//处理金额
				if(this.payori=="goods"){
					this.getCanUseCouponUser()
				}else{
					this.dealWithPrice()
				}
				//默认地址
				this.selectXCXUserAddressfale()
			}else{
				this.$util.routerPath("/404")
			}
		}else{
			this.$util.routerPath("/404")
		}
	},
	computed: {
		...mapState(['sysUserInfo','userType','bsnName','uuid','nickName','avatarUrl','phone','isLogin','openid','shareCode','oricode','unionid']),
	},
	methods: {
		// 多图删除
		removeimglist(index) {
		    this.corporateList.splice(index, 1)
		},
		// 多图增加
		addimglist(url) {
			if(this.corporateList.length>=2){
				this.$message.error("最多上传2张")
				return false;
			}
		    this.corporateList.push(url)
		},
		//弹窗信息
		showTost(text){
			this.$message.warning(text);
		},
		//选择优惠券
		selectCoupon(index) {
			if(this.selectcpindex == Number(index)){
				//取消选择
				this.selectcpindex = -1
				this.selectcp = null
			}else{
				//选择
				this.selectcpindex = Number(index)
				this.selectcp = this.couponlist[index]
			}
			//重新计算
			this.dealWithPrice()
		},
		//获取可用优惠券
		getCanUseCouponUser() {
			var _this = this;
			var params = {};
			params['userUuid'] = this.uuid;
			var goodsList = this.orderList[0].goodsList
			var drugsCartVoList = []
			for(var i=0;i<goodsList.length;i++){
				var goodsobj = {
					cartId:goodsList[i].cartId,
					goodsNum:goodsList[i].goodsNum,
					goodsUuid:goodsList[i].goodsUuid,
					totalPrice:Number(goodsList[i].goodsPirce)*Number(goodsList[i].goodsNum),
				}
				drugsCartVoList.push(goodsobj)
			}
			params['drugsCartVoList'] = drugsCartVoList
			//console.log(params);
			// 发动请求
			this.$http.post("coupon/couponUser/canUseCoupons", params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var couponlist = res.data.filter((item) => {
						return item.canUse == 0; //筛选出匹配数据 0是可使用，1是不行
					});
					if (couponlist.length > 0) {
						couponlist.forEach((item,index)=>{
							if(item.startDate){
								var date = item.startDate.split("T")
								if (date.length > 0) {
								  item["startTime"] = date[0] //.replace(/-/g, '.')
								}
							}
							if(item.endDate){
								var date1 = item.endDate.split("T")
								if (date1.length > 0) {
								  item["endTime"] = date1[0] //.replace(/-/g, '.')
								}
							}
						})
						_this.couponlist = couponlist;
						_this.selectcp = couponlist[0];
						_this.selectcpindex = 0;
					}
					_this.dealWithPrice()
				} else {
					hideLoading();
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//计算金额
		dealWithPrice(){
			var orderList = this.orderList
			var goodLength = 0
			var oriAllmoney = 0
			for(var i=0;i<orderList.length;i++){
				for (let j = 0; j < orderList[i].goodsList.length; j++) {
					goodLength +=orderList[i].goodsList[j].goodsNum
				    var price = Number(orderList[i].goodsList[j].goodsPirce) * Number(orderList[i].goodsList[j].goodsNum)
					orderList[i].goodsList[j]["danprice"] = price.toFixed(2)
				    oriAllmoney += price
				}
			}
			//原来价格
			this.oriAllmoney = oriAllmoney.toFixed(2)
			this.goodLength = goodLength
			if (this.couponlist.length > 0) {
				//有优惠券，并且选中
				if (this.selectcp) {
					var realprice = Number(oriAllmoney) - Number(this.selectcp.amount);
					this.Allmoney = realprice.toFixed(2);
				} else {
					//不使用优惠券
					this.Allmoney = oriAllmoney.toFixed(2)
				}
			}else{
				this.Allmoney = oriAllmoney.toFixed(2)
			}
		},
		//支付
		submitOrder(){
			if(!this.isLogin){
				this.$message.error("请先登录采购商账号~")
				return;
			}
			if(this.userType!="purchase"){
				this.$message.error("账号类型不允许采购，请登录采购商账号~")
				return;
			}
			var _this = this
			var goodsList = this.orderList[0].goodsList
			if(goodsList.length<=0){
				this.$message.error("参数丢失，暂无法采购！请返回首页重新采购！")
				return;
			}
			var drugsCartVoList = []
			var drugsGroupVo = {}
			if(this.payori=="team"){
				drugsGroupVo = {
					goodsNum:goodsList[0].goodsNum,
					goodsUuid:goodsList[0].goodsUuid,
					totalPrice:Number(goodsList[0].goodsPirce)*Number(goodsList[0].goodsNum),
					batchid:goodsList[0].batchid
				}
			}else{
				for(var i=0;i<goodsList.length;i++){
					var goodsobj = {
						cartId:goodsList[i].cartId,
						goodsNum:goodsList[i].goodsNum,
						goodsUuid:goodsList[i].goodsUuid,
						totalPrice:Number(goodsList[i].goodsPirce)*Number(goodsList[i].goodsNum),
						batchid:goodsList[i].batchid
					}
					drugsCartVoList.push(goodsobj)
				}
			}
			var params = {
				payType:this.payType,//支付类型 MA小程序 WEB网页（失效） CASH货到付款 POINT积分
				address:this.addressobj,
				useCoupon:false,
				couponKey:"",
				couponUuid:"",
				representUuid:this.oricode,
				remarks:this.orderList[0].remarks,
				drugsCartVoList:drugsCartVoList,
				userOpenid:this.openid,
				userUnion:this.unionid,
				userUuid:this.uuid,
				groupNo:this.groupNo,//	拼团号
				groupType:this.groupType,//	类型 0创建 1加入
				corporateList:this.corporateList,//对公证明
			}
			if(this.selectcp&&this.selectcpindex>-1){
				params["couponKey"] = this.selectcp.couponKey
				params["couponUuid"] = this.selectcp.couponUuid
				params["useCoupon"] = true
			}
			var control = ""
			if(this.payori=="goods"){
				control = "drugs/pay/create"
			}
			if(this.payori=="tejia"){
				control = "drugs/special/pay/create"
			}
			if(this.payori=="exchange"){
				control = "drugs/point/pay/create"
			}
			if(this.payori=="team"){
				control = "drugs/group/pay/create"
				//商品对象
				params["drugsGroupVo"] = drugsGroupVo
			}
			if(!control){
				this.$message.error("支付失败，请返回重新采购下单！")
				return;
			}
			params["invoiceDetail"] = this.invoiceDetail
			//console.log(params)
			showLoading();
			this.$http.post(control, params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					if(_this.payType=="WEIXIN_NATIVE" || _this.payType=="ALIPAY_NATIVE" || _this.payType=="UNIONPAY_NATIVE"){
						//继续支付
						_this.dopay(res)
					}else{
						hideLoading();
						_this.$message.success("提交订单成功~")
						_this.$message.success("提交订单成功~")
						setTimeout(() => {
							if(_this.payori=="goods" || _this.payori=="tejia" || _this.payori=="exchange"){
								//普通订单
								_this.$router.push({ path: '/mine', query: {redirect: 'order'}})
							}else{
								//拼团订单
								_this.$router.push({ path: '/mine', query: {redirect: 'groupOrder'}})
							}
						}, 500);
					}
					//刷新购物车购物车
					localStorage.setItem("iffreshcar","true")
				} else {
					hideLoading();
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		// 发动支付
		dopay(res){
			var _this = this
			_this.isShowPay = true
			setTimeout(function() {
			_this.$refs.refdoPay.creatQrCode(res.data.payMsg)
			}, 800)
			hideLoading();
		},
		// 取消支付
		clickpayclose() {
			var _this = this
		    this.$confirm('此操作将关闭支付弹窗，请确定已经支付成功，关闭后，将会跳转到订单列表页，是否继续?', '提示', {
		      confirmButtonText: '确定支付成功',
		      cancelButtonText: '已取消支付',
		      type: 'warning'
		    }).then(() => {
		        _this.isShowPay = false
		        if(_this.payori=="goods" || _this.payori=="tejia" || _this.payori=="exchange"){
		        	//普通订单
		        	_this.$router.push({ path: '/mine', query: {redirect: 'order'}})
		        }else{
		        	//拼团订单
		        	_this.$router.push({ path: '/mine', query: {redirect: 'groupOrder'}})
		        }
		      })
		      .catch(() => {
				_this.isShowPay = false
		        _this.$message({
		          type: 'info',
		          message: '已取消'
		        })
		    })
		},
		//查询默认地址
		selectXCXUserAddressfale(){
			if(this.isLogin){
				this.addressobj = this.sysUserInfo.info.receivingAddress
			}else{
				this.$message.error("请先登录采购商账号~")
			}
			
		},
		//修改发票类型
		handcommand1(type){
			console.log(type)
			this.invoiceDetail.invoiceType = type
		},
	}
};
</script>
<style lang="scss" scoped>
.pay_canuse_coupon_box{
	.pay_top_box{
		height: 50px;
		display:flex;
		align-items: center;
		.line{
			width: 4px;
			height: 20px;
			background: #1370FB;
		}
		.text{
			margin-left: 16px;
			font-size: 20px;
			font-weight: bold;
			color: #333333;
		}
	}
	.coupon_data_box{
		width: 100%;
		background: #FFFFFF;
		border: 1px solid #E0E0E0;
		.cpdatalist{
			width: 100%;
			box-sizing: border-box;
			padding: 10px;
			display: flex;
			flex-wrap: wrap;
			.cpitem{
				width: 278px;
				margin: 10px;
				height: 138px;
				border: 1px solid #e0e0e0;
				cursor: pointer;
				.topbox{
					height: 98px;
					padding: 0 16px;
					background: #e0e0e0;
					display: flex;
					flex-direction: column;
					justify-content: center;
					.amout{
						font-size: 25px;
						color: #FFFFFF;
						span{
							font-size: 14px;
							margin-left: 5px;
						}
					}
					.timer{
						margin-top: 8px;
						font-size: 15px;
						color: #FFFFFF;
					}
				}
				.bottombox{
					height: 40px;
					padding: 0 16px;
					display: flex;
					align-items: center;
					font-size: 16px;
					color: #666666;
				}
			}
			.active{
				border: 1px solid #1370FB;
				.topbox{
					background: linear-gradient(180deg, #1370FB 0%, #13C3FB 100%);
				}
			}
		}
		.amout_text{
			width: 100%;
			height: 48px;
			background-color: #f2f2f2;
			box-sizing: border-box;
			padding: 0 20px;
			display: flex;
			align-items: center;
			.text1{
				font-size: 14px;
				color: #333333;
			}
			.text2{
				margin-left: 5px;
				font-size: 16px;
				font-weight: 600;
				color: #FF2F2F;
			}
			.text3{
				margin-left: 20px;
				font-size: 14px;
				color: #666666;
			}
		}
	}
	.corporate_data_box{
		width: 100%;
		background: #FFFFFF;
		border: 1px solid #E0E0E0;
		padding: 10px;
		box-sizing: border-box;
	}
}
</style>
