<template>
  <div v-if="isShow" class="doPaydiv">
    <div class="dopaymask" />
    <div class="paycontent">
      <img class="closeimg" src="../../assets/static/phone_close.png" @click="clickclose">
      <div class="dopaycon">
        <div class="title" v-if="payType=='WEIXIN_NATIVE'">微信扫码支付</div>
		<div class="title" v-if="payType=='ALIPAY_NATIVE'">支付宝扫码支付</div>
        <div ref="qrCodeUrl" class="qrcode" />
        <div class="number">支付金额:<span>￥{{ minPrice }}</span></div>
        <div class="tips">提示：请确认支付成功后，然后请点击关闭此窗口，系统将会跳转到订单页面</div>
      </div>
    </div>
  </div>
</template>

<script>
import QRCode from 'qrcodejs2'
export default {
  name: 'DoPay',
  props: {
    isShow: {
      type: Boolean,
      default() {
        return false
      }
    },
    minPrice: {
      type: String,
      default() {
		  return '0.00'
      }
    },
	payType: {
	  type: String,
	  default() {
		  return ''
	  }
	}
  },
  data() {
    return {

    }
  },
  mounted() {
    // this.creatQrCode()
  },
  methods: {
    creatQrCode(text) {
      var qrcode = new QRCode(this.$refs.qrCodeUrl, {
        text: text, // 需要转换为二维码的内容
        width: 188,
        height: 188,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.H
      })
    },
    clickclose() {
      this.$emit('clickpayclose')
    }

  }
}
</script>

<style scoped>
.doPaydiv{
	width: 100%;
	height: 100%;
	position: fixed;
	left: 0;
	top: 0;
	z-index: 18;
	display: flex;
	align-items: center;
	justify-content: center;
}
.dopaymask{
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	background-color: rgba(0,0,0,.5);
	z-index: 10;
}
.paycontent{
	width: 360px;
	/* height: 358px; */
	position: relative;
	z-index: 1000;
	background-color: #fff;
	border-radius: 5px;
	box-shadow: 0 2px 8px rgb(26 26 26 / 10%);
}
.closeimg{
	width: 25px;
	height: 25px;
	position: absolute;
	top: 15px;
	right: 15px;
	z-index: 10;
	cursor: pointer;
}
.dopaycon{
	padding: 38px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.title{
	font-size: 24px;
	font-weight: 500;
	margin-bottom: 8px;
}
.qrcode{
	width: 188px;
	height: 188px;
	margin: 10px 0;
}
.number{
	font-size: 16px;
	color: #969696;
	margin-top: 8px;
}
.number span{
	color: #da9e55;
}
.tips{
	margin-top: 15px;
	font-size: 13px;
	color: #969696;
}
</style>
